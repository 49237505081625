import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const SecondPage = () => (
  <Layout>
    <SEO title="Look Look" />
    <header className="looklook-header">
      <Link to="/">
        <h1>LOOK</h1>
        <h2>LOOK</h2>
      </Link>
    </header>
    <div className="information care">
      <h2>Guidelines</h2>
      <p>
        We are opening our doors May 22nd under new health and safety
        regulations.
      </p>
      <p>
        In order to secure the health and safety of our clients and staff at
        LOOK LOOK, we will be following the CDC’s strictest protocols,
        recommendations and the guidelines set by The Utah State Health
        Department. In order to meet those standards we have made the following
        changes to our shop, shop procedures, and our day to day work flow:
      </p>
      <br />
      <ul>
        <li>
          Masks must be worn at all times by staff and clients. If you will be
          using your own mask please preform a *Match Test to ensure the mask
          quality meets highest standards of protection or one will be provided
          for you.
        </li>
        <li>No walk-ins. Appointments only.</li>
        <li>Consultations will be done digitally</li>
        <li>No Face or Neck Tattoos at this time</li>
        <li>Our Lobby and Patio is currently closed</li>
        <li>
          Employee temperatures will be taken at the beginning of their shifts
          with a health screening everyday.
        </li>
        <li>
          Tattoo care instructions will be on our website to reduce handouts.
          https://looklooktattoo.com/ or sent via email or text at client
          request.
        </li>
        <li>
          No Entourage, friends, or family at this time. Client/Artist
          appointments only.
        </li>
        <li>
          Employee shifts will be staggered to follow social distancing
          standards.
        </li>
        <li>
          We have rearranged our shop to meet a 10ft distancing between stations
          to ensure social distancing can be maintained.
        </li>
        <li>
          We have installed Plexiglass barriers to our entrance and art areas.
        </li>
      </ul>
      <br />
      <h2>Client Check In</h2>
      <br />
      <p>
        Please be on time for your appointment. Once you have arrived for your
        appointment call us from the parking lot 801-419-0508 to check in. We
        will then let your Artist know you have arrived. We ask that you wait in
        your vehicle until your Artist is ready for you. All personal belongings
        must be kept on you. Leave any unnecessary items at home or in your car.
        We will then take your temperature and conduct a brief health screening.
        We will then sanitize your hands at entry.
      </p>
      <br />
      <p>
        Our shop has always maintained strenuous cleaning and sterilization
        practices for our work areas and hard surfaces where hand contact
        occurs, but we are increasing the frequency of those efforts. We follow
        the highest health and hygiene protocols at all times.
      </p>
      <br />
      <small>
        *Match Test- Put your mask on and light a match or lighter hold it in
        front of your mask and blow. If the flame blows out the mask isn’t
        offering the highest amount of protection. If it doesn’t blow out then
        you have yourself a great mask!
      </small>
    </div>
  </Layout>
);

export default SecondPage;
